import * as UI from '@@ui';
import { set } from 'lodash';
import React from 'react';
import { Timeout } from 'react-number-format/types/types';

/**
 * Context Provider to refresh the route
 * Pauses rendering children for 500ms to allow the route to refresh
 */

export const RefreshContext = React.createContext({
  isRefreshing: false,
  refresh: (callback?: () => Promise<void>) => {},
});

export const RefreshProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const refreshTimeout = React.useRef<Timeout>();
  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const refresh = React.useCallback(async (callback?: () => Promise<void>) => {
    setIsRefreshing(true);
    if(callback) {
      await callback();
      setIsRefreshing(false);
    } else {
      refreshTimeout.current = setTimeout(() => {
        setIsRefreshing(false);
      }, 500);
    }
  }, []);

  React.useEffect(() => {
    return () => {
      if (refreshTimeout.current) {
        clearTimeout(refreshTimeout.current);
      }
    };
  }, []);

  return (
    <RefreshContext.Provider value={{ isRefreshing, refresh }}>
      {isRefreshing ? <UI.Spinner /> : children}
    </RefreshContext.Provider>
  );
};

export const useRefresh = (callback?: () => Promise<void>) => {
  //return React.useContext(RefreshContext).refresh(callback);
  const { refresh } = React.useContext(RefreshContext);
  return () => refresh(callback);
};
